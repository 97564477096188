import { NodeEditor } from 'rete';
import { t } from 'i18next';

import { AreaExtensions, AreaPlugin, Zoom } from 'rete-area-plugin';
import {
    ConnectionPlugin,
    Presets as ConnectionPresets,
} from 'rete-connection-plugin';
import {
    ReactPlugin,
    Presets as ReactPresets,
} from 'rete-react-plugin';
import { createRoot } from 'react-dom/client';

import {
    ContextMenuPlugin,
    Presets as ContextMenuPresets,
} from 'rete-context-menu-plugin';
import { DockPlugin, DockPresets } from 'rete-dock-plugin';
import { CustomNodeStart } from './nodes/customization/CustomNodeStart';
import { CustomNode } from './nodes/customization/CustomNode';
import { CustomMenu, CustomCommon, CustomSearch, CustomSubitems, CustomItemWithIcon } from './nodes/customization/CustomContextMenu';
import {
    MessageNode,
    InputNode,
    SwitchNode,
    SetVarsNode,
    SubroutineNode,
    DelayNode,
    LocationNode,
    LeaveNode,
    InviteUserNode,
    CheckTimeNode,
    MediaNode,
    DistributeChatNode,
    StartNode,
    HttpRequestNode,
    EmailNode,
    GptAssistantNode,
    InteractiveNode,
    StartSubNode,
    GotoNode,
    StartSectionNode,
} from './nodes';
import {
    TextArea,
    TextAreaControl,
    Input,
    InputControl,
    InputModal,
    InputModalControl,
    SwitchModal,
    SwitchModalControl,
    MediaModal,
    MediaModalControl,
    CheckTimeModal,
    CheckTimeModalControl,
    InviteUserModal,
    InviteUserModalControl,
    SetVarsModal,
    SetVarsModalControl,
    MessageModalControl,
    MessageModal,
    DistributeChatModalControl,
    DistributeChatModal,
    HttpRequestModalControl,
    HttpRequestModal,
    EmailModal,
    EmailModalControl,
    GptAssistantModal,
    GptAssistantModalControl,
    InteractiveModalControl,
    InteractiveModal,
    SubroutineModal,
    SubroutineModalControl,
    LeaveModal,
    LeaveModalControl,
    LocationModal,
    LocationModalControl,
    DelayModal,
    DelayModalControl,
    StartSubModal,
    StartSubModalControl,
    GotoModalControl,
    GotoModal,
    StartSectionModalControl,
    StartSectionModal,
} from './controls';
import { Schemes, AreaExtra, DiContainer } from './types';
import {
    AutoArrangePlugin,
    Presets as ArrangePresets,
    ArrangeAppliers,
} from 'rete-auto-arrange-plugin';
import { deleteNode, copyNode, cutNode, pasteNode } from './shorcut_functions';
import {
    HistoryExtensions,
    HistoryPlugin,
    Presets as HistoryPresets,
} from 'rete-history-plugin';
import { SelectableConnection } from './components/SelectableConnection';

type createEditorModel = {
    destroy: () => void;
    arranger: () => void;
    di: DiContainer;
};

export async function createEditor(container: HTMLElement): Promise<createEditorModel> {
    const editor = new NodeEditor<Schemes>();
    const area = new AreaPlugin<Schemes, AreaExtra>(container);
    const connection = new ConnectionPlugin<Schemes, AreaExtra>();
    const reactRender = new ReactPlugin<Schemes, AreaExtra>({ createRoot });
    const dock = new DockPlugin<Schemes>();
    const arrange = new AutoArrangePlugin<Schemes>();
    const history = new HistoryPlugin<Schemes>();
    area.area.setZoomHandler(null);
    HistoryExtensions.keyboard(history);
    history.addPreset(HistoryPresets.classic.setup());

    // call on Ctrl press
    const listener = (e: KeyboardEvent) => {
        if (e.ctrlKey) {
            area.area.setZoomHandler(new Zoom(0.1));
        }
    };
    // call on Ctrl release
    const listenerRelease = (e: KeyboardEvent) => {
        if (!e.ctrlKey) {
            area.area.setZoomHandler(null);
        }
    };

    document.addEventListener('keydown', listener);
    document.addEventListener('keyup', listenerRelease);

    dock.addPreset(DockPresets.classic.setup({ area, size: 150, scale: 0.5 }));
    const applier = new ArrangeAppliers.TransitionApplier<Schemes, never>({
        duration: 500,
        timingFunction: (t) => t,
        async onTick(): Promise<void> {
            await AreaExtensions.zoomAt(area, editor.getNodes());
        },
    });

    arrange.addPreset(ArrangePresets.classic.setup());

    reactRender.addPreset(
        ReactPresets.classic.setup({
            customize: {
                node(context) {
                    if (context.payload.label === 'Start') {
                        return CustomNodeStart;
                    }
                    return CustomNode;
                },
                control(data) {
                    if (data.payload instanceof TextAreaControl) {
                        return TextArea;
                    }
                    if (data.payload instanceof InputControl) {
                        return Input;
                    }
                    if (data.payload instanceof InputModalControl) {
                        return InputModal;
                    }
                    if (data.payload instanceof SwitchModalControl) {
                        return SwitchModal;
                    }
                    if (data.payload instanceof MediaModalControl) {
                        return MediaModal;
                    }
                    if (data.payload instanceof CheckTimeModalControl) {
                        return CheckTimeModal;
                    }
                    if (data.payload instanceof InviteUserModalControl) {
                        return InviteUserModal;
                    }
                    if (data.payload instanceof SetVarsModalControl) {
                        return SetVarsModal;
                    }
                    if (data.payload instanceof MessageModalControl) {
                        return MessageModal;
                    }
                    if (data.payload instanceof DistributeChatModalControl) {
                        return DistributeChatModal;
                    }
                    if (data.payload instanceof HttpRequestModalControl) {
                        return HttpRequestModal;
                    }
                    if (data.payload instanceof EmailModalControl) {
                        return EmailModal;
                    }
                    if (data.payload instanceof GptAssistantModalControl) {
                        return GptAssistantModal;
                    }
                    if (data.payload instanceof InteractiveModalControl) {
                        return InteractiveModal;
                    }
                    if (data.payload instanceof SubroutineModalControl) {
                        return SubroutineModal;
                    }
                    if (data.payload instanceof LeaveModalControl) {
                        return LeaveModal;
                    }
                    if (data.payload instanceof LocationModalControl) {
                        return LocationModal;
                    }
                    if (data.payload instanceof DelayModalControl) {
                        return DelayModal;
                    }
                    if (data.payload instanceof StartSubModalControl) {
                        return StartSubModal;
                    }
                    if (data.payload instanceof GotoModalControl) {
                        return GotoModal;
                    }
                    if (data.payload instanceof StartSectionModalControl) {
                        return StartSectionModal;
                    }
                    return null;
                },
                connection() {
                    return SelectableConnectionBind;
                },
            },
        })
    );

    const SelectableConnectionBind = (props: { data: Schemes['Connection'] }) => {
        const id = props.data.id;
        const label = 'connection';
        return (
            <SelectableConnection
                {...props}
                click={() => {
                    selector.add(
                        {
                            id,
                            label,
                            translate() { },
                            unselect() {
                                props.data.selected = false;
                                area.update('connection', id);
                            },
                        },
                        accumulating.active()
                    );
                    props.data.selected = true;
                    area.update('connection', id);
                }}
            />
        );
    };

    const di: DiContainer = {
        updateNode: (id) => area.update('node', id),
        updateControl: (id) => area.update('control', id),
        editor,
        area,
    };

    /**Context menu for the customization of the nodes must take into account the order,
     * if this changes you must adjust the switch in the customization file,
     * if you add a new node also add the corresponding icon.
    */
    const contextMenu = new ContextMenuPlugin<Schemes>({
        items: ContextMenuPresets.classic.setup([
            [t('nodes.start'), (): StartNode => new StartNode()],
            [t('nodes.Distribute Chat'), (): DistributeChatNode => new DistributeChatNode(di)],
            [t('nodes.Messages'), [
                [t('nodes.Message'), (): MessageNode => new MessageNode(di)],
                [t('nodes.Media'), (): MediaNode => new MediaNode(di)],
                [t('nodes.Location'), (): LocationNode => new LocationNode(di)],
            ]],
            [t('nodes.Entries'), [
                [t('nodes.Input'), (): InputNode => new InputNode(di)],
                [t('nodes.Interactive'), (): InteractiveNode => new InteractiveNode(di)],
            ]],
            [t('nodes.Validations'), [
                [t('nodes.Check Time'), (): CheckTimeNode => new CheckTimeNode(di)],
                [t('nodes.Switch'), (): SwitchNode => new SwitchNode(di)],
            ]],
            [t('nodes.Subroutines'), [
                [t('nodes.StartSub'), (): StartSubNode => new StartSubNode(di)],
                [t('nodes.Subroutine'), (): SubroutineNode => new SubroutineNode(di)],
                [t('nodes.StartSection'), (): StartSectionNode => new StartSectionNode(di)],
                [t('nodes.Goto'), (): GotoNode => new GotoNode(di)],
            ]],
            [t('nodes.Advanced'), [
                [t('nodes.HttpRequest'), (): HttpRequestNode => new HttpRequestNode(di)],
                [t('nodes.GptAssistant'), (): GptAssistantNode => new GptAssistantNode(di)],
            ]],
            [t('nodes.Utils'), [
                [t('nodes.Invite User'), (): InviteUserNode => new InviteUserNode(di)],
                [t('nodes.Leave'), (): LeaveNode => new LeaveNode(di)],
                [t('nodes.Email'), (): EmailNode => new EmailNode(di)],
                [t('nodes.Set Vars'), (): SetVarsNode => new SetVarsNode(di)],
                [t('nodes.Delay'), (): DelayNode => new DelayNode(di)],
            ]],
        ]),
    });

    reactRender.addPreset(ReactPresets.contextMenu.setup({
        customize: {
            main: () => CustomMenu,
            item: (props) => {
                return () => <CustomItemWithIcon data={props}/>;
            },
            common: () => CustomCommon,
            search: () => CustomSearch,
            subitems: () => CustomSubitems,
        },
    }));

    editor.use(area);

    area.use(reactRender);
    area.use(connection);
    area.use(contextMenu);
    area.use(dock);
    area.use(arrange);
    area.use(history);

    dock.add(() => new StartNode());
    dock.add(() => new MessageNode(di));
    dock.add(() => new InputNode(di));
    dock.add(() => new SwitchNode(di));
    dock.add(() => new MediaNode(di));
    dock.add(() => new SetVarsNode(di));
    dock.add(() => new SubroutineNode(di));
    dock.add(() => new StartSubNode(di));
    dock.add(() => new CheckTimeNode(di));
    dock.add(() => new InviteUserNode(di));
    dock.add(() => new LeaveNode(di));
    dock.add(() => new LocationNode(di));
    dock.add(() => new DelayNode(di));
    dock.add(() => new DistributeChatNode(di));
    dock.add(() => new HttpRequestNode(di));
    dock.add(() => new EmailNode(di));
    dock.add(() => new GptAssistantNode(di));
    dock.add(() => new InteractiveNode(di));
    dock.add(() => new GotoNode(di));
    dock.add(() => new StartSectionNode(di));

    connection.addPreset(ConnectionPresets.classic.setup());
    reactRender.addPreset(ReactPresets.classic.setup());
    reactRender.addPreset(ReactPresets.contextMenu.setup());

    const selector = AreaExtensions.selector();
    const accumulating = AreaExtensions.accumulateOnCtrl();

    AreaExtensions.selectableNodes(area, selector, { accumulating });
    AreaExtensions.simpleNodesOrder(area);

    // Keyboard shortcuts
    document.addEventListener('keydown', async (e) => {
        const modalElement = document.getElementsByClassName('MuiDialog-root')[0];
        if (modalElement) return;

        if (e.key === 'Delete') deleteNode(selector, editor);
        if (e.ctrlKey && e.key === 'c') copyNode(selector, editor);
        if (e.ctrlKey && e.key === 'v') pasteNode(di);
        if (e.ctrlKey && e.key === 'x') cutNode(selector, editor);
    });

    return {
        destroy: () => area.destroy(),
        arranger: () => arrange.layout({ applier }),
        di,
    };
};